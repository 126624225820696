'use client';

import { MutableRefObject, useEffect, useRef } from 'react';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import { FNCProduct } from 'shared/types/product/Product';
import { HISTORY_LIMIT } from 'shared/types/product/RecentlyViewed';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';
import ExtraDetails from './extra-details';
import MainDetails from './main-details';
import ProductDock from './product-dock';
import ShopTheCollection from './shop-the-collection';
import useTrackProductView from './utils/track-product-view';

interface ContentManagerProps {
  product: DataSourceV2<FNCProduct>;
  financeTerms: DataSourceV2<FinanceProduct[]>;
  onDeliveryDateChange(date: Date | null): void;
}

export interface PDPReferences {
  review: MutableRefObject<HTMLButtonElement | null>;
  description: MutableRefObject<HTMLButtonElement | null>;
  dimensions: MutableRefObject<HTMLButtonElement | null>;
}

function storeAsRecentlyViewedProduct(product: FNCProduct): void {
  const historyLimit = HISTORY_LIMIT;
  const currSKU = product.selectedVariant.sku;
  const recentSKUs = JSON.parse(localStorage.getItem('recentSKUs') || '[]') as string[];

  // user is refreshing the page
  if (recentSKUs && recentSKUs.slice(-1)[0] == currSKU) return;

  // sku currently isn't in the recently viewed list
  if (recentSKUs && !recentSKUs.includes(currSKU)) {
    // prune if needed
    if (recentSKUs.length >= historyLimit) {
      recentSKUs.shift();
    }
    recentSKUs.push(currSKU);
    localStorage.setItem('recentSKUs', JSON.stringify(recentSKUs));
    // move sku to the top of the list if it's already in the recently viewed list
  } else if (recentSKUs && recentSKUs.includes(currSKU)) {
    const index = recentSKUs.findIndex((v) => v == currSKU);
    recentSKUs.splice(index, 1);
    recentSKUs.push(currSKU);
    localStorage.setItem('recentSKUs', JSON.stringify(recentSKUs));
  }
}

export default function ContentManager({
  data: { product, financeTerms, onDeliveryDateChange },
}: TasticProps<ContentManagerProps>) {
  useTrackProductView(product.dataSource.productId);

  useEffect(() => {
    storeAsRecentlyViewedProduct(product.dataSource);
  }, [product.dataSource]);

  const references = {
    review: useRef<HTMLButtonElement>(null),
    description: useRef<HTMLButtonElement>(null),
    dimensions: useRef<HTMLButtonElement>(null),
  };

  return (
    <section className="mt-8">
      <MainDetails
        product={product.dataSource}
        financeTerms={financeTerms.dataSource}
        onDeliveryDateChange={onDeliveryDateChange}
        references={references}
      />
      <ExtraDetails product={product.dataSource} references={references} />
      <ShopTheCollection product={product.dataSource} />
      <ProductDock product={product.dataSource} references={references} />
    </section>
  );
}
