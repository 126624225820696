import clsx from 'clsx';
import { MdCheck, MdErrorOutline } from 'react-icons/md';
import { FNCVariantAvailability } from 'shared/types/furniturechoice/variant';
import { useFormat } from '~/helpers/hooks/useFormat';
import buildStockFormattedDate from '~/helpers/utils/furniturechoice/build-stock-formatted-date';

interface StockStatusProp {
  isOnStock: FNCVariantAvailability['isOnStock'];
  isOnLowStock: FNCVariantAvailability['isOnLowStock'];
  expectedDelivery: FNCVariantAvailability['expectedDelivery'];
  isRds: FNCVariantAvailability['isRds'];
  isDiscontinued: FNCVariantAvailability['isDiscontinued'];
  stockSummary: FNCVariantAvailability['stockSummary'];
}

export default function StockStatus({
  isOnStock,
  isOnLowStock,
  expectedDelivery,
  isRds,
  isDiscontinued,
  stockSummary,
}: StockStatusProp): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  let stockStatus: 'in-stock' | 'low-stock' | 'discontinued' | 'no-stock-with-incoming' | 'no-stock' | null = null;

  if (isOnStock) {
    stockStatus = isOnLowStock ? 'low-stock' : 'in-stock';
  } else {
    stockStatus = expectedDelivery ? 'no-stock-with-incoming' : 'no-stock';
  }

  if (isDiscontinued || (isRds && !isOnStock)) {
    stockStatus = 'discontinued';
  }

  return (
    <p
      data-testid="card-product-status"
      className={clsx('mt-4 flex items-start text-14 font-medium leading-4 text-green-2', {
        'text-red-2': stockStatus === 'low-stock' || stockStatus === 'discontinued',
      })}
    >
      {stockStatus === 'in-stock' && (
        <>
          <MdCheck size={14} className="mr-1 inline" />
          <span>{stockSummary?.short}</span>
        </>
      )}

      {stockStatus === 'low-stock' && (
        <>
          <MdErrorOutline size={14} className="mr-1 inline" />
          <span>{stockSummary?.short}</span>
        </>
      )}

      {stockStatus === 'no-stock-with-incoming' && (
        <span>
          {formatMessage({
            id: 'tastics.product.product-stock.out_of_stock_with_expected_delivery',
            values: {
              date: buildStockFormattedDate(new Date(expectedDelivery as string)),
            },
          })}
        </span>
      )}

      {stockStatus === 'no-stock' && <span>{formatMessage({ id: 'tastics.product.product-stock.out_of_stock' })}</span>}

      {stockStatus === 'discontinued' && (
        <span>{formatMessage({ id: 'tastics.product.product-stock.unavailable' })}</span>
      )}
    </p>
  );
}
