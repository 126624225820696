import { useContext } from 'react';
import { ShippingMethod } from 'shared/types/cart';
import useSWR, { SWRResponse } from 'swr';
import { sdk } from 'sdk';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';

interface UseShippingMethodArgs {
  dispatchCode?: string;
}

export default function useShippingMethod({ dispatchCode }: UseShippingMethodArgs): SWRResponse<ShippingMethod> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  return useSWR<ShippingMethod>(
    `/action/furniturechoice-shipping-method/getShippingMethod/${dispatchCode}`,
    async () => {
      return await executeWithProgress<ShippingMethod>(async () => {
        const response = await sdk.callAction<ShippingMethod>({
          actionName: 'furniturechoice-shipping-method/getShippingMethod',
          payload: { dispatchCode },
          skipQueue: true,
        });

        if (response.isError) {
          throw response.error;
        }

        return response.data;
      });
    },
  );
}
