import { useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { FNCProduct } from 'shared/types/product/Product';
import GA4Button from '~/components/furniturechoice/ga4-button';
import SideModal from '~/components/furniturechoice/side-modal';
import { useFormat } from '~/helpers/hooks/useFormat';
import WriteReviewManager from './WriteReviewManager';

export interface ReturnResult {
  ok: boolean;
  error: string;
}

export default function WriteReviewOpener({ product }: { product: FNCProduct }) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <GA4Button
        ga4_event_name="product_review"
        ga4_event_param_1_name="interaction_type"
        ga4_event_param_1_value="Open"
        ga4_event_param_2_name="active_product"
        ga4_event_param_2_value={product.name}
        role="button"
        className="py-2 text-14 font-normal text-salmon-1"
        aria-expanded={isOpen}
        onClick={onClick}
      >
        {formatMessage({ id: 'tastics.review.write_a_review' })}
        <MdChevronRight className="inline" />
      </GA4Button>
      <SideModal key={'write_a_review'} isOpen={isOpen} onModalClose={onClick}>
        <WriteReviewManager product={product} />
      </SideModal>
    </div>
  );
}
