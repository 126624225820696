import { MdArrowForwardIos } from 'react-icons/md';
import { FNCProduct } from 'shared/types/product/Product';
import Link from '~/components/furniturechoice/link';

interface ViewCollectionCardProps {
  product?: FNCProduct;
  collectionUrl: string;
}

export default function ViewCollectionCard({ collectionUrl, product }: ViewCollectionCardProps): JSX.Element {
  return (
    <Link
      ga4_event_name="view_collection"
      ga4_event_param_1_name="element"
      ga4_event_param_1_value="Shop the Collection"
      ga4_event_param_2_name="active_product"
      ga4_event_param_2_value={product?.name}
      data-testid="view-collection-link"
      className="surface-grey-5 flex items-center justify-center rounded-md lg:h-full"
      href={collectionUrl}
    >
      <span className="text-13 font-semibold leading-3.5 lg:text-18 lg:leading-5">
        View Collection <MdArrowForwardIos className="inline" />
      </span>
    </Link>
  );
}
