import { useEffect, useRef } from 'react';
import { sdk } from '~/sdk';
import { useCookieConsent } from '~/utils/use-cookie-consent';

interface TrackProductViewPayload {
  productId: string;
}

async function trackProductView(payload: TrackProductViewPayload) {
  return await sdk.callAction<{ ok: boolean }>({
    actionName: 'furniturechoice-relewise/trackProductView',
    payload: payload,
  });
}

const useTrackProductView = (productId: string | undefined) => {
  const { functionalConsent } = useCookieConsent();
  const prevProductId = useRef<string | null>(null);

  useEffect(() => {
    // Prevent tracking if no consent is given
    if (!functionalConsent) {
      return;
    }
    // Prevent tracking if productId is not available
    if (!productId) {
      return;
    }
    // Prevent duplicate tracking
    if (prevProductId.current === productId) {
      return;
    }
    // Track product view asynchronously
    trackProductView({
      productId,
    });

    prevProductId.current = productId;
  }, [functionalConsent, productId]);
};

export default useTrackProductView;
