import { useId, useRef } from 'react';
import clsx from 'clsx';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { FNCProduct } from 'shared/types/product/Product';
import { SwiperContainer } from 'swiper/element';
import useSwiper from '~/helpers/hooks/furniturechoice/use-swiper';
import ProductCard from '../product-card';
import ViewCollectionCard from '../view-collection-card';

interface ProductCardSliderProps {
  product: FNCProduct;
  products: FNCProduct[];
  collectionUrl: string;
}

export default function ProductCardSlider({ product, products, collectionUrl }: ProductCardSliderProps): JSX.Element {
  const swiperRef = useRef<SwiperContainer>(null);

  const prevSlideButtonId = useId();
  const nextSlideButtonId = useId();
  const { isInitialized } = useSwiper({
    ref: swiperRef,
    props: {
      breakpoints: {
        0: {
          enabled: false,
          spaceBetween: 18,
          slidesPerView: 4,
          navigation: { enabled: false },
          scrollbar: { enabled: false },
        },
        1024: {
          enabled: true,
          spaceBetween: 18,
          slidesPerView: 4,
          navigation: {
            prevEl: `[data-id="${prevSlideButtonId}"]`,
            nextEl: `[data-id="${nextSlideButtonId}"]`,
            enabled: true,
          },
          scrollbar: { enabled: true },
        },
      },
    },
  });

  return (
    <div data-testid="product-card-slider" className="mt-8 hidden lg:block">
      <div className="mb-7 flex">
        <div className="ml-auto flex gap-x-2">
          <button
            data-id={prevSlideButtonId}
            className="surface-salmon-4 flex aspect-square h-7 w-7 items-center justify-center rounded"
          >
            <MdChevronLeft size={24} />
          </button>
          <button
            data-id={nextSlideButtonId}
            className="surface-salmon-4 flex aspect-square h-7 w-7 items-center justify-center rounded"
          >
            <MdChevronRight size={24} />
          </button>
        </div>
      </div>
      <swiper-container
        // @ts-ignore: React doesn't support className for web components
        // eslint-disable-next-line tailwindcss/classnames-order
        class={clsx(
          'mb-2.5 flex gap-x-[18px] overflow-hidden',
          { 'max-lg:container': !isInitialized },
          // eslint-disable-next-line prettier/prettier
          '[--swiper-scrollbar-bg-color:#e6e6e6] [--swiper-scrollbar-drag-bg-color:black] [--swiper-scrollbar-bottom:calc(0px)] [--swiper-scrollbar-sides-offset:0px] [--swiper-scrollbar-size:3px]',
        )}
        ref={swiperRef}
        init={false}
        slidesPerView="auto"
        scrollbar
      >
        {products &&
          products.map((v) => (
            <swiper-slide
              // @ts-ignore: React does/n't support className for web components
              class="mb-6 w-[calc(25%-13.5px)] rounded-md"
              data-testid="product-slide-image"
              key={v.variants[0].sku}
            >
              <ProductCard variant={v.selectedVariant} imageColSpanPercent={0.25} />
            </swiper-slide>
          ))}

        <swiper-slide
          // @ts-ignore: React doesn't support className for web components
          class={clsx('mb-6 h-auto w-[calc(25%-13.5px)] rounded-md')}
          data-testid="product-slide-image"
        >
          <ViewCollectionCard collectionUrl={collectionUrl} product={product} />
        </swiper-slide>
      </swiper-container>
    </div>
  );
}
