import { useState } from 'react';
import clsx from 'clsx';
import { FNCProduct } from 'shared/types/product/Product';
import { useFormat } from '~/helpers/hooks/useFormat';
import ReviewWidgetHeader from '../../main-details/review-widget-header';

interface DockNavigationProps {
  product: FNCProduct;
  references: {
    review: React.RefObject<HTMLButtonElement>;
    description: React.RefObject<HTMLButtonElement>;
    dimensions: React.RefObject<HTMLButtonElement>;
  };
}

export default function DockNavigation({ product, references }: DockNavigationProps) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const [activeButton, setActiveButton] = useState<string | null>(null);

  function handleMenuClick(buttonName: string, sectionRef: React.RefObject<HTMLButtonElement>) {
    setActiveButton(buttonName);
    if (sectionRef.current) {
      if (sectionRef.current instanceof HTMLButtonElement) {
        if (sectionRef.current.ariaExpanded === 'false') {
          sectionRef.current.click();
        }
      }
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <ol className="container flex items-center justify-between border-t border-grey-3 py-4 text-14 leading-tight lg:hidden">
      <li className="relative">
        <button
          className={clsx(
            'color-change-transition relative font-normal hover:text-salmon-5',
            activeButton === 'overview' ? 'font-bold text-salmon-4' : '',
            activeButton === 'overview' &&
              'after:absolute after:inset-x-0 after:bottom-[-18px] after:border-b-[3px] after:border-solid after:border-salmon-4',
          )}
          type="button"
          onClick={() => handleMenuClick('overview', references.description)}
        >
          {formatMessage({ id: 'tastics.product.product-dock.navi_overview' })}
        </button>
      </li>
      <li className="relative">
        {product.reviewRatingStatistics ? (
          <ReviewWidgetHeader product={product} reviewRef={references.review.current} />
        ) : (
          <button
            className={clsx(
              'color-change-transition relative font-normal hover:text-salmon-5',
              activeButton === 'reviews' ? 'font-bold text-salmon-4' : '',
              activeButton === 'reviews' &&
                'after:absolute after:inset-x-0 after:bottom-[-18px] after:border-b-[3px] after:border-solid after:border-salmon-4',
            )}
            type="button"
            onClick={() => handleMenuClick('reviews', references.review)}
          >
            {formatMessage({ id: 'tastics.product.product-dock.navi_reviews' })}
          </button>
        )}
      </li>
      <li className="relative">
        <button
          className={clsx(
            'color-change-transition relative font-normal hover:text-salmon-5',
            activeButton === 'dimensions' ? 'font-bold text-salmon-4' : '',
            activeButton === 'dimensions' &&
              'after:absolute after:inset-x-0 after:bottom-[-18px] after:border-b-[3px] after:border-solid after:border-salmon-4',
          )}
          type="button"
          onClick={() => handleMenuClick('dimensions', references.dimensions)}
        >
          {formatMessage({ id: 'tastics.product.product-dock.navi_dimensions' })}
        </button>
      </li>
    </ol>
  );
}
