import { useContext } from 'react';
import clsx from 'clsx';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { FNCProduct } from 'shared/types/product/Product';
import { LineItem } from 'shared/types/wishlist';
import { useWishlist } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { sendGA4TransactionEvent } from '~/utils/send-ga4-event';

interface WishlistButtonProps {
  product: FNCProduct;
  type?: 'page' | 'card';
}

export default function WishlistButton({ product, type }: WishlistButtonProps): JSX.Element {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const wishlistManager = useWishlist();

  const lineItem = wishlistManager.data?.lineItems?.find((lineItem) => lineItem.productId === product.productId);

  const style = type || 'page';

  function handleClick() {
    executeWithProgress(async () => {
      if (wishlistManager.data) {
        if (lineItem) {
          await wishlistManager.removeLineItem(wishlistManager.data, lineItem);
        } else {
          const optimisticLineItem: LineItem = {
            lineItemId: product.productId || '',
            productId: product.productId,
            variant: product.selectedVariant,
          };

          await wishlistManager.addToWishlist(wishlistManager.data, optimisticLineItem);
        }
        sendGA4TransactionEvent('add_to_wishlist', [product]);
      }
    });
  }
  const buttonClass = clsx('flex min-h-[48px] min-w-[48px] items-center justify-center', {
    'surface-white rounded-md border-[1px] border-grey-3': style == 'page',
  });
  return (
    <button className={buttonClass} onClick={handleClick} data-testid="wishlist-button">
      {lineItem ? (
        <MdFavorite className="fill-red-3 text-24" data-testid="favorite-filled" />
      ) : style == 'page' ? (
        <MdFavoriteBorder className="text-24 text-grey-4" data-testid="favorite-empty" />
      ) : (
        <MdFavorite className="text-24 text-grey-4" data-testid="favorite-empty" />
      )}
    </button>
  );
}
