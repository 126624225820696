import { FormEvent, useState, useContext } from 'react';
import { FNCProduct } from 'shared/types/product/Product';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sendGA4Event } from '~/utils/send-ga4-event';
import submitReview from './submitReview';
import WriteReviewForm from './WriteReviewForm';
import WriteReviewSent from './WriteReviewSent';

export interface ReturnResult {
  ok: boolean;
  error: string;
}

export default function WriteReviewManager({ product }: { product: FNCProduct }) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const [reviewSent, setReviewSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { showProgressIndicator, hideProgressIndicator } = useContext(ProgressIndicatorContext);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setErrorMessage('');
    showProgressIndicator();

    sendGA4Event('product_review', 'interaction_type', 'Submit', 'active_product', product.name);

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData);

    const result = await submitReview(payload);

    hideProgressIndicator();

    if (!result || result.isError == true) {
      if (result) {
        setErrorMessage(result.error.message);
      } else {
        setErrorMessage(formatMessage({ id: 'general.error_message.unknown_error' }));
      }
    } else {
      if (result.data.ok) {
        setReviewSent(true);
      } else {
        setErrorMessage(result.data.error);
      }
    }
  }

  return (
    <div className="">
      <h2 className="font-sans text-20 font-bold text-grey-6">
        {formatMessage({ id: 'tastics.review.write_a_review' })}
      </h2>
      {reviewSent ? (
        <WriteReviewSent />
      ) : (
        <>
          <WriteReviewForm product={product} onSubmit={handleSubmit} />
          <div className="mt-4 text-red-1">{errorMessage}</div>
        </>
      )}
    </div>
  );
}
