import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { FNCVariant } from 'shared/types/product/Product';
import DynamicTag from '~/components/furniturechoice/dynamic-tag';
import Link from '~/components/furniturechoice/link';
import VariantMainImage from '~/components/furniturechoice/variant-main-image';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';

interface ProductCardProp {
  variant: FNCVariant;
  imageColSpanPercent: number;
}

export default function ProductCard({ variant, imageColSpanPercent }: ProductCardProp): JSX.Element {
  const { locale } = useParams();

  return (
    <Link data-testid="product-card" key={variant.sku} href={`/p/${variant.slug}` || '#'}>
      <div>
        <VariantMainImage
          className="aspect-square w-full lg:rounded-md"
          sku={variant.sku}
          lazyLoadInView
          colSpanPercent={imageColSpanPercent}
        />

        <div className="mt-2.5">
          <DynamicTag
            data-testid="product-card-title"
            className="font-playfair-display text-20 font-normal leading-5"
            as={'h3'}
          >
            {variant.attributes?.['descriptors_alt-title']}
          </DynamicTag>

          <p className="mt-1.5 line-clamp-2 font-sans text-14 font-light leading-4 text-grey-5">
            {variant.metafields.productName}
          </p>
        </div>
        <div className="mt-2">
          <span
            data-testid="main-price"
            className={clsx('text-16 font-semibold', {
              'text-discount-1': variant.discountedPrice || variant.attributes?.['descriptors_is-clearance'],
            })}
          >
            {CurrencyHelpers.formatForCurrency(variant.discountedPrice || variant.price || 0, locale)}
          </span>
          {variant.discountedPrice && (
            <p className="text-14 text-grey-5 line-through" data-testid="compare-at-price">
              was {CurrencyHelpers.formatForCurrency(variant.price || 0, locale)}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
}
