import { forwardRef, ForwardedRef } from 'react';
import clsx from 'clsx';
import { MdKeyboardArrowDown } from 'react-icons/md';
import DynamicTag from '~/components/furniturechoice/dynamic-tag';
import GA4Button from '../../ga4-button';
import { GA4EventProps } from '../../with-ga4-event';

interface HeadingProps {
  headingId: string;
  headingTag: string;
  headingText: string;
  panelId: string;
  isExpanded: boolean;
  onClick(): void;
  headingElem?: JSX.Element;
  navigationId?: string;
  ga4EventProps?: Record<'open' | 'close', GA4EventProps>;
}

export function Heading(
  {
    headingId,
    headingTag,
    headingText,
    headingElem,
    panelId,
    isExpanded,
    onClick,
    navigationId,
    ga4EventProps,
  }: HeadingProps,
  ref: ForwardedRef<HTMLButtonElement>,
): JSX.Element {
  return (
    <DynamicTag as={headingTag} data-testid="heading">
      <GA4Button
        className="flex w-full items-center justify-between overflow-hidden py-10"
        data-testid="button"
        type="button"
        id={headingId}
        aria-expanded={isExpanded}
        aria-controls={panelId}
        onClick={onClick}
        ref={ref}
        {...((isExpanded ? ga4EventProps?.['close'] : ga4EventProps?.['open']) ?? {})}
      >
        <div id={navigationId} className="flex gap-8 text-left">
          <span className="text-28 font-medium leading-9">{headingText}</span>
          {headingElem}
        </div>
        <MdKeyboardArrowDown
          className={clsx('fill-grey-5 text-24 transition-transform duration-standard ease-standard', {
            'rotate-180': isExpanded,
          })}
        />
      </GA4Button>
    </DynamicTag>
  );
}

export default forwardRef<HTMLButtonElement, HeadingProps>(Heading);
