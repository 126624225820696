'use client';

import { useContext, useState } from 'react';
import { MdOutlineShoppingBasket } from 'react-icons/md';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import { FNCProduct } from 'shared/types/product/Product';
import { USPFinanceAttributes } from 'shared/types/product/USP';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import ProductSticker from '~/components/furniturechoice/variant-sticker';
import { useCart } from '~/frontastic/hooks';
import { MinicartContext } from '~/helpers/contexts/furniturechoice/minicart';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import useShippingMethod from '~/helpers/hooks/furniturechoice/use-shipping-method';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sendGA4TransactionEvent } from '~/utils/send-ga4-event';
import MediaSlider from './media-slider';
import OrderSwatchesSection from './order-swatches-section';
import PostcodeDeliveryCalendar from './postcode-delivery-calendar';
import Pricing from './pricing';
import ReviewWidgetHeader from './review-widget-header';
import StockStatus from './stock-status';
import USP from './usp-bar';
import VariantSelector from './variant-selector';
import WishlistButton from './wishlist-button';
import ZeroFinanceLabel from './zero-finance-label';
import { PDPReferences } from '..';

interface MainDetailsProps {
  product: FNCProduct;
  financeTerms: FinanceProduct[];
  onDeliveryDateChange(date: Date | null): void;
  references: PDPReferences;
}

export default function MainDetails({ product, financeTerms, references }: MainDetailsProps) {
  const { openMinicart } = useContext(MinicartContext);
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const isProductUnavailable =
    (product.selectedVariant.metafields.isRds && !product.selectedVariant.isOnStock) ||
    product.selectedVariant.metafields.isDiscontinued;

  const dispatchCode = product.selectedVariant.attributes?.['logistics_dispatch-service']?.key;
  const { data: deliveryCalendar } = useShippingMethod({ dispatchCode });

  const cart = useCart();

  async function addToCart() {
    await executeWithProgress(() => cart.addItem({ sku: product.selectedVariant.sku }, 1));

    sendGA4TransactionEvent('add_to_cart', [product]);
    openMinicart();
  }

  const financeablePrice = product.selectedVariant.discountedPrice ||
    product.selectedVariant.price || { centAmount: 0, currencyCode: 'GBP', fractionDigits: 2 };

  const [financeModalOpen, setFinanceModalOpen] = useState<boolean>(false);

  function handleFinanceModalToggle() {
    setFinanceModalOpen(!financeModalOpen);
  }

  const finaceUspAttributes: USPFinanceAttributes = {
    ...product.usps.financeContent,
    financeProducts: financeTerms,
    financeModalOpen: financeModalOpen,
    onClick: function (): void {
      handleFinanceModalToggle();
    },
  } as USPFinanceAttributes;

  return (
    <div className="grid grid-cols-1 gap-y-3 lg:container lg:grid-cols-[54%,1fr] lg:gap-x-10">
      <div className="relative">
        <ProductSticker variant={product.selectedVariant} size="medium" className="left-2.5 top-2.5" />
        <MediaSlider slides={product.selectedVariant.metafields.assets} product={product} />
      </div>

      <div className="max-lg:container">
        <header>
          <h1 className="text-36 font-normal leading-[3rem]">
            {product.selectedVariant.attributes?.['descriptors_alt-title']}
          </h1>

          <p className="mt-1 leading-5 text-grey-5">{product.name}</p>
          <ReviewWidgetHeader product={product} reviewRef={references.review?.current} />
          <p className="mt-1 text-12 leading-[1.2rem] text-grey-4">Code: {product.selectedVariant.sku}</p>
        </header>

        <Pricing className="mt-5" variant={product.selectedVariant} bundles={product.bundles} />

        <ZeroFinanceLabel
          financeableAmount={financeablePrice}
          financeProducts={financeTerms}
          onClick={() => handleFinanceModalToggle()}
        />

        <VariantSelector variants={product.mergedVariants} options={product.options} />

        {product.colourOptionSwatches && product.colourOptionSwatches.length > 0 && (
          <OrderSwatchesSection
            swatches={product.colourOptionSwatches}
            selectedVariant={product.selectedVariant}
            product={product}
          />
        )}

        <StockStatus
          isOnStock={product.selectedVariant.isOnStock || false}
          isOnLowStock={product.selectedVariant.metafields.isOnLowStock}
          expectedDelivery={product.selectedVariant.metafields.expectedDelivery}
          isRds={product.selectedVariant.metafields.isRds}
          isDiscontinued={product.selectedVariant.metafields.isDiscontinued}
          stockSummary={product.selectedVariant.metafields.stockSummary}
        />

        {deliveryCalendar && (
          <PostcodeDeliveryCalendar shippingMethod={deliveryCalendar} defaultValue="" onDeliveryDateChange={() => {}} />
        )}

        <div>
          {product.selectedVariant && (
            <div className="mt-4 flex w-full flex-row gap-2">
              <FNCButton
                className="surface-green-2 min-h-[48px] w-full"
                icon={<MdOutlineShoppingBasket />}
                onClick={() => addToCart()}
                disabled={isProductUnavailable}
              >
                {formatMessage({ id: 'tastics.product.content-manager.add_to_basket' })}
              </FNCButton>

              <WishlistButton product={product} />
            </div>
          )}
        </div>

        <div className="mt-5">
          <USP
            aboutUsData={product.usps.aboutUsContent}
            freeDeliveryData={product.usps.freeDeliveryContent}
            guaranteeData={product.usps.guaranteeContent}
            guaranteeDuration={product.usps.guaranteeDuration}
            financeData={finaceUspAttributes}
            returnsData={product.usps.returnsContent}
            productPrice={financeablePrice.centAmount ?? 0}
          />
        </div>
      </div>
    </div>
  );
}

//export default forwardRef<RefObject<any>, MainDetailsProps>(MainDetails);
