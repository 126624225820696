import { useCallback, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { FNCProduct } from 'shared/types/product/Product';
import Accordion from '~/components/furniturechoice/accordion';
import { useFormat } from '~/helpers/hooks/useFormat';
import DimensionTable from './dimension-table';
import PageDescription from './page-description';
import ReviewsTable from './review-table/ReviewsTable';
import ReviewWidget from './review-table/ReviewWidget';
import SpecificationTable from './specification-table';
import { PDPReferences } from '..';

interface ExtraDetailsProps {
  product: FNCProduct;
  references?: PDPReferences;
}

export default function ExtraDetails({ product, references }: ExtraDetailsProps) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const page = useRef(0);

  const reviewPanel = <ReviewsTable product={product} page={page} />;
  const descriptionPanel = <PageDescription product={product} />;
  const specsPanel = <SpecificationTable product={product} />;
  const dimensionsPanel = <DimensionTable product={product} />;

  const [expandedPanels, setExpandedPanels] = useState({
    review: false,
    description: false,
    dimensions: false,
  });

  const handlePanelExpansion = useCallback((panel: keyof typeof expandedPanels, status: boolean) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [panel]: status,
    }));
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hash = window.location.hash;

      if (hash === '#review-ac' && references?.review?.current instanceof HTMLButtonElement) {
        if (references.review.current.ariaExpanded === 'false') {
          references.review.current.click();
        }
        references.review.current.scrollIntoView();
      }

      if (hash === '#description-ac' && references?.description?.current instanceof HTMLButtonElement) {
        if (references.description.current.ariaExpanded === 'false') {
          references.description.current.click();
        }
        references.description.current.scrollIntoView();
      }

      if (hash === '#dimensions-ac' && references?.dimensions?.current instanceof HTMLButtonElement) {
        if (references.dimensions.current.ariaExpanded === 'false') {
          references.dimensions.current.click();
        }
        references.dimensions.current.scrollIntoView();
      }
    }
  }, [references?.review, references?.description, references?.dimensions]);

  const accordionData = [
    {
      headingTag: 'h2',
      headingText: formatMessage({ id: 'tastics.product.content-manager.product_description' }),
      panel: descriptionPanel,
      isExpanded: expandedPanels.description,
      callback: (status: boolean) => handlePanelExpansion('description', status),
      reference: references?.description,
      navigationId: 'description-ac',
    },
    {
      headingTag: 'h2',
      headingText: formatMessage({ id: 'tastics.product.content-manager.reviews' }),
      panel: reviewPanel,
      isExpanded: expandedPanels.review,
      headingElem: (
        <div
          className={clsx(
            'transition-opacity ',
            { 'opacity-100': !expandedPanels.review },
            { 'opacity-0': expandedPanels.review },
          )}
        >
          <ReviewWidget product={product} />
        </div>
      ),
      callback: (status: boolean) => handlePanelExpansion('review', status),
      reference: references?.review,
      navigationId: 'review-ac',
    },
    {
      headingTag: 'h2',
      headingText: formatMessage({ id: 'tastics.product.content-manager.product_specification' }),
      panel: specsPanel,
      isExpanded: false,
    },
    {
      headingTag: 'h2',
      headingText: formatMessage({ id: 'tastics.product.content-manager.product_dimensions' }),
      panel: dimensionsPanel,
      isExpanded: expandedPanels.dimensions,
      callback: (status: boolean) => handlePanelExpansion('dimensions', status),
      reference: references?.dimensions,
      navigationId: 'dimensions-ac',
    },
  ];

  const accordionDataWithGa4Data = accordionData.map((data) => ({
    ...data,
    ga4EventProps: {
      open: {
        ga4_event_name: 'product_accordian',
        ga4_event_param_1_name: 'interaction_type',
        ga4_event_param_1_value: 'Open',
        ga4_event_param_2_name: 'active_product',
        ga4_event_param_2_value: product.name,
        ga4_event_param_3_name: 'element',
        ga4_event_param_3_value: data.headingText,
      },
      close: {
        ga4_event_name: 'product_accordian',
        ga4_event_param_1_name: 'interaction_type',
        ga4_event_param_1_value: 'Close',
        ga4_event_param_2_name: 'active_product',
        ga4_event_param_2_value: product.name,
        ga4_event_param_3_name: 'element',
        ga4_event_param_3_value: formatMessage({ id: 'tastics.product.content-manager.product_description' }),
      },
    },
  }));

  return (
    <div className="container">
      <Accordion className="mt-10 border-t border-grey-3" contents={accordionDataWithGa4Data} isOnDemandPanel />
    </div>
  );
}
