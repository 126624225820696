'use client';

import { useContext, useState, useEffect } from 'react';
import { MdOutlineShoppingBasket } from 'react-icons/md';
import { FNCProduct } from 'shared/types/product/Product';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import { useCart } from '~/frontastic/hooks';
import { MinicartContext } from '~/helpers/contexts/furniturechoice/minicart';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import DockNavigation from './dock-navigation';
import { PDPReferences } from '..';
import Pricing from '../main-details/pricing';

interface ProductDockProps {
  product: FNCProduct;
  references?: PDPReferences;
}

export default function ProductDock({ product, references }: ProductDockProps) {
  const { openMinicart } = useContext(MinicartContext);
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const [isLargeMobileScreen] = useMediaQuery(325);
  const [showDock, setShowDock] = useState(false);

  const cart = useCart();

  async function addToCart() {
    await executeWithProgress(() => cart.addItem({ sku: product.selectedVariant.sku }, 1));
    openMinicart();
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPastElement = document.querySelector('#usp-list-item-free_delivery');
      if (scrollPastElement) {
        const scrollPastPosition = scrollPastElement.getBoundingClientRect().bottom + window.scrollY;
        const currentScroll = window.scrollY;

        if (currentScroll > scrollPastPosition) {
          setShowDock(true);
        } else {
          setShowDock(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={`fixed top-0 z-300 w-full border-b bg-white transition-transform ${
        showDock ? 'translate-y-0' : '-translate-y-full'
      }`}
    >
      <div className="container my-4 flex items-center justify-between lg:mx-auto">
        <div className="hidden text-18 leading-tight lg:block">{product.name}</div>
        <Pricing variant={product.selectedVariant} bundles={product.bundles} />
        <div>
          {product.selectedVariant && (
            <FNCButton
              className="surface-green-2 min-h-[48px] w-full"
              icon={<MdOutlineShoppingBasket />}
              onClick={() => addToCart()}
            >
              {isLargeMobileScreen
                ? formatMessage({ id: 'tastics.product.content-manager.add_to_basket' })
                : formatMessage({ id: 'tastics.product.content-manager.add' })}
            </FNCButton>
          )}
        </div>
      </div>
      {showDock && references && <DockNavigation product={product} references={references} />}
    </div>
  );
}
