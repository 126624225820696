import { Fragment, HTMLAttributes, MutableRefObject, useId, useState } from 'react';
import Heading from './heading';
import Panel from './panel';
import { GA4EventProps } from '../with-ga4-event';

interface AccordionContent {
  headingTag: string;
  headingText: string;
  panel: JSX.Element | string;
  isExpanded: boolean;
  headingElem?: JSX.Element;
  callback?: (b: boolean) => void;
  reference?: MutableRefObject<HTMLButtonElement | null>;
  navigationId?: string;
  ga4EventProps?: Record<'open' | 'close', GA4EventProps>;
}

interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
  contents: AccordionContent[];
  isOnDemandPanel?: boolean;
}

export default function Accordion({ contents, isOnDemandPanel = false, ...props }: AccordionProps): JSX.Element {
  const [accordionStatuses, setAccordionStatuses] = useState<boolean[]>(contents.map((content) => content.isExpanded));
  const headingId = useId();
  const panelId = useId();

  function handleHeadingClick(indexToUpdate: number, callback?: (b: boolean) => void): void {
    const newAccordionStatuses = accordionStatuses.map((status, index) => {
      if (index === indexToUpdate) {
        if (callback) callback(!status);
        return !status;
      }

      return status;
    });

    setAccordionStatuses(newAccordionStatuses);
  }

  const contentEls = contents.map((content, index) => {
    const headingIndexId = `${headingId}${index}`;
    const panelIndexId = `${panelId}${index}`;

    if (content.panel) {
      return (
        <Fragment key={content.headingText}>
          <Heading
            headingId={headingIndexId}
            headingTag={content.headingTag}
            headingText={content.headingText}
            headingElem={content.headingElem}
            panelId={panelIndexId}
            isExpanded={accordionStatuses[index]}
            onClick={() => handleHeadingClick(index, content.callback)}
            ref={content.reference}
            navigationId={content.navigationId}
            ga4EventProps={content.ga4EventProps}
          />
          <Panel
            panelId={panelIndexId}
            panelContent={(isOnDemandPanel && accordionStatuses[index]) || !isOnDemandPanel ? content.panel : <></>}
            headingId={headingIndexId}
            isExpanded={accordionStatuses[index]}
          />
        </Fragment>
      );
    } else {
      return null;
    }
  });

  return (
    <div className="border-t border-grey-3" {...props}>
      {contentEls}
    </div>
  );
}
